<template>
    <div
        v-if="text" 
        class="complaint"
    >
        <div class="container-header">
            <h3>{{ title }}</h3>
            <button v-if="copyable" class="copy btn" @click="copyText(text)">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 8H10C8.89543 8 8 8.89543 8 10V18C8 19.1046 8.89543 20 10 20H18C19.1046 20 20 19.1046 20 18V10C20 8.89543 19.1046 8 18 8Z" stroke="#305BF2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M16 8V6C16 5.46957 15.7893 4.96086 15.4142 4.58579C15.0391 4.21071 14.5304 4 14 4H6C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V14C4 14.5304 4.21071 15.0391 4.58579 15.4142C4.96086 15.7893 5.46957 16 6 16H8" stroke="#305BF2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                Copiar
            </button>
            <div v-else class="chevron" @click="handleOpen()" v-bind:class="{ rotateUp: !open, rotateDown: open }">
                <ChevronUp />
            </div>
        </div>
        <div v-if="this.open && this.hasHtml(text)" v-html="text" class="break-spaces" v-bind:class="{ openTable: open, closeTable: !open }"></div>
        <div v-else-if="this.open" class="break-spaces" v-bind:class="{ openTable: open, closeTable: !open }">{{ text }}</div>
    </div>
</template>

<script>
import ChevronUp from '@/assets/icons/chevron-up.svg'

export default {
components: { ChevronUp },

    props: {
        title: String,
        text: String,
        copyable: Boolean,
    },

    methods : {
      hasHtml(value) {
        return /<\/?[a-z][\s\S]*>/i.test(value);
      },
      copyText(text){
        navigator.clipboard.writeText(text)
      },

      handleOpen(){
        this.open = !this.open
      }
    },

    data() {
      return{
        open: true,
      }
    },


}
</script>

<style lang="scss" scoped>
.complaint {
    display: flex;
    flex-direction: column;
    margin: 20px 0;

    .container-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        h3 {
            color: var(--dark-blue);
            font-weight: 600;
            font-size: 20px;
        }

        .copy {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-right: 20px;
            font-weight: 700;
            color: var(--blue-500);
            padding: 5px 15px;
            border-radius: 20px;
            transition: .5s;
            svg {
                margin-right: 5px;
            }
        }

        .copy:hover {
            transition: .5s;
            background-color: var(--neutral-200);
        }

        .chevron {
            width: 25px;
            height: 25px;
            margin-right: 30px;

            > svg {
                cursor: pointer;
                stroke: var(--neutral-600);
                transition: .5s;
            }
            }

            .rotateUp {
                animation: rotateUp .5s;
                -webkit-animation-fill-mode: forwards;
                -moz-animation-fill-mode: forwards;
                animation-fill-mode: forwards;
            }

            .rotateDown {
                animation: rotateDown .5s;
                -webkit-animation-fill-mode: forwards;
                -moz-animation-fill-mode: forwards;
                animation-fill-mode: forwards;
            }

            @keyframes rotateUp {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(180deg);
            }
            }

            @keyframes rotateDown {
            0% {
                transform: rotate(180deg);
            }
            100% {
                transform: rotate(0deg);
            }
            }
    }
}
</style>
